import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_snackbar, {
        color: _ctx.$store.state.snackbar.color,
        "model-value": !!_ctx.$store.state.snackbar.message,
        timeout: 5000,
        location: "top right",
        "update:model-value": "",
        variant: "elevated"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$store.state.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["color", "model-value"]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}