import { PermissionsState } from '@/shared/interfaces/store-types';
import { LevelOnePermission } from '@/shared/interfaces/permissions';
import { SideBarIcons } from '@/shared/constants/sideBar';

export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';
export const SET_ACTIVE_PERMISSION = 'SET_ACTIVE_PERMISSION';
export const GET_LEVEL_ONE_PERMISSIONS = 'GET_LEVEL_ONE_PERMISSIONS';
export const GET_ACTIVE_PERMISSIONS = 'GET_ACTIVE_PERMISSIONS';

export default {
  namespaced: true,
  state: {
    all: [],
    levelOne: [],
    secondLevel: [],
    thirdLevel: [],
    permissions: [],
    activePermission: null,
  } as PermissionsState,
  getters: {
    [GET_LEVEL_ONE_PERMISSIONS](state: { levelOne: LevelOnePermission[] }) {
      return state.levelOne;
    },
    [GET_ACTIVE_PERMISSIONS](state: {
      activePermission: LevelOnePermission[];
    }) {
      return state.activePermission;
    },
  },
  mutations: {
    [SET_ACTIVE_PERMISSION](
      state: PermissionsState,
      payload: {
        permission: LevelOnePermission;
      }
    ) {
      state.activePermission = payload.permission;
    },
    [SET_ALL_PERMISSIONS](
      state: PermissionsState,
      payload: {
        permissions: LevelOnePermission[];
      }
    ) {
      state.all = payload.permissions;
    },
    [SET_USER_PERMISSIONS](
      state: PermissionsState,
      payload: {
        permissions: LevelOnePermission[];
      }
    ) {
      state.levelOne = [];
      state.secondLevel = [];
      state.thirdLevel = [];
      state.permissions = [];

      const { permissions: userPermissions } = payload;

      state.levelOne = userPermissions.map((level) => {
        const generatedKey = level.permission_level_1
          ?.toLowerCase()
          .replace(/\s+/g, '_');

        return {
          ...level,
          icon: SideBarIcons.filter((i) => i.key === generatedKey)[0].icon,
          routeKey: generatedKey,
          routePath: generatedKey,
          sub_levels: level.sub_levels.map((s) => ({
            ...s,
            routeKey: s.permission_level_2?.toLowerCase().replace(/\s+/g, '_'),
            routePath: s.permission_level_2?.toLowerCase().replace(/\s+/g, '_'),
            sub_levels: s.sub_levels?.map((s2) => ({
              ...s2,
              routeKey: s2.permission_level_3
                ?.toLowerCase()
                .replace(/\s+/g, '_'),
              routePath: s2.permission_level_3
                ?.toLowerCase()
                .replace(/\s+/g, '_'),
            })),
          })),
        };
      });

      state.levelOne.forEach((level) => {
        level.sub_levels.forEach((subLevel) => {
          state.secondLevel.push(subLevel);
          subLevel.sub_levels?.forEach((subLevel2) => {
            state.thirdLevel.push(subLevel2);
            subLevel2.permissions?.forEach((permission) => {
              state.permissions.push(permission);
            });
          });
        });
      });
    },
  },
};
