export const GET_SNACKBAR_STATUS = 'GET_SNACKBAR_STATUS';
export const SET_SNACKBAR_STATUS = 'SET_SNACKBAR_STATUS';

export const SNACKBAR_COLORS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};
export default {
  namespaced: true,
  state: {
    message: null,
    color: 'error',
  } as { message: string | null; color: 'success' | 'warning' | 'error' },
  getters: {
    [GET_SNACKBAR_STATUS](state: {
      message: string;
      success: boolean;
      warning: boolean;
    }) {
      return {
        message: state.message,
        success: state.success,
        waring: state.warning,
      };
    },
  },
  mutations: {
    [SET_SNACKBAR_STATUS](
      state: { message: string | null; color: 'success' | 'warning' | 'error' },
      payload: { message: string; color: 'success' | 'warning' | 'error' }
    ) {
      state.message = payload.message;
      state.color = payload.color;
    },
  },
};
