import { AuthState } from '@/shared/interfaces/store-types';
import { UserProfile } from '@/shared/interfaces/user';

export const GET_IS_AUTHENTICATED = 'GET_IS_AUTHENTICATED';
export const SET_AUTH = 'SET_AUTH';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    profile: {
      permissions: [],
      user_details: {
        bio: '',
        city: '',
        gender: '',
        address: '',
        country: '',
        user_id: null,
        timezone: '',
        bank_name: '',
        role_name: '',
        role_type: '',
        user_name: '',
        bank_branch: '',
        expiry_date: '',
        issued_date: '',
        postal_code: '',
        user_status: 'INACTIVE',
        website_url: '',
        date_of_birth: '',
        phone_number2: '',
        id_picture_url: '',
        approval_status: 'PENDING',
        document_number: '',
        business_unit_id: null,
        document_type_id: null,
        role_description: '',
        bank_account_name: '',
        bank_account_type: '',
        state_or_province: '',
        business_unit_name: '',
        bank_account_number: '',
        language_preference: '',
        profile_picture_url: '',
        business_unit_type_id: null,
        business_unit_type_name: '',
        business_unit_type_parent_id: null,
      },
      company_alias: '',
      user_id: null,
      iat: null,
      exp: null,
      aud: '',
      iss: '',
    },
  } as AuthState,
  getters: {
    [GET_IS_AUTHENTICATED](state: { isAuthenticated: boolean }) {
      return state.isAuthenticated && !!localStorage.getItem('access_token');
    },
  },
  mutations: {
    [SET_AUTH](
      state: AuthState,
      payload: {
        status: boolean;
        tokens?: { accessToken: string; refreshToken: string };
      }
    ) {
      if (payload.status && payload.tokens) {
        localStorage.setItem('access_token', payload.tokens.accessToken);
        localStorage.setItem('refresh_token', payload.tokens.refreshToken);
      } else {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
      state.isAuthenticated = payload.status;
    },
    [SET_USER_PROFILE](state: AuthState, payload: UserProfile) {
      state.profile = payload;
    },
  },
};
