import { createApp } from 'vue';
import App from './App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import { key, store } from '@/store';
import { loadFonts } from '@/plugins/webfontloader';
import { firebaseApp } from '@/plugins/firebase';
import vuetify from '@/plugins/vuetify';
import VueAxios from 'vue-axios';
import { VueFire } from 'vuefire';
import axios from '@/plugins/axios';
import 'carbon-components/css/carbon-components.css';
import CarbonComponentsVue from '@carbon/vue';

const app = createApp(App);

loadFonts().then();

app
  .use(vuetify)
  .use(CarbonComponentsVue)
  .use(router)
  .use(store, key)
  .use(VueAxios, axios)
  .use(VueFire, {
    firebaseApp,
    modules: [],
  });
app.mount('#app');
