import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { State } from '@/shared/interfaces/store-types';
import AuthModule from './modules/auth';
import spinnerModule from './modules/spinner';
import createPersistedState from 'vuex-persistedstate';
import snackbarModule from '@/store/modules/snackbar';
import appNavigationModule from '@/store/modules/app-navigation';

export const key: InjectionKey<Store<State>> = Symbol();
export const AUTH_MODULE = 'auth';
export const SPINNER_MODULE = 'spinner';
export const SNACKBAR_MODULE = 'snackbar';
export const NAVIGATION_MODULE = 'appNavigation';
export const store = createStore<State>({
  strict: false,
  modules: {
    auth: AuthModule,
    spinner: spinnerModule,
    snackbar: snackbarModule,
    appNavigation: appNavigationModule,
  },
  plugins: [createPersistedState()],
});

export function useStore() {
  return baseUseStore(key);
}

export * as auth from './modules/auth';
export * as spinner from './modules/spinner';
export * as snackbar from './modules/snackbar';
export * as appNavigation from './modules/app-navigation';
